import headerImage from "./assets/wine/heading.jpg";
import photoDay1 from "./assets/wine/day-1.jpg";
import photoDay2 from "./assets/wine/day-2.jpg";
import photoDay3 from "./assets/wine/day-3.jpg";
import photoDay4 from "./assets/wine/day-4(final).jpg";
import photoDay5 from "./assets/wine/day-5(final).jpg";
import photoDay6 from "./assets/wine/day-6.jpg";
import photoDay7 from "./assets/wine/day-7(final).jpg";
import photoDay8 from "./assets/wine/day-8(final).jpg";
import photoDay9 from "./assets/wine/day-9(final).jpg";
import photoDay10 from "./assets/wine/day-10(final).jpg";
import photoDay11 from "./assets/wine/day-11(final).jpg";
import photoDay12 from "./assets/wine/day-12(final).jpg";
import photoDay13 from "./assets/wine/day-13(final).jpg";
import photoDay14 from "./assets/wine/day-14(final).jpg";

export const itenaries = [{
    id: "wine-safari",
    heading: ["South African", "Wine Safari", "Adventure"],
    headingImg: headerImage,
    overview: "Join us on a South African Nature and Wine Tour starting in Johannesburg, where we will explore the stunning beauty and diverse wildlife of the region, with opportunities to take part in jeep safari's and cultural experiences. We will also visit the renowned wine regions of the Garden Route, Franschhoek, and Cape Town, where we will have the chance to sample the finest wines and learn about the winemaking process from the experts. End the trip with a day at leisure in Cape Town, or opt for an additional wine tasting day in Franschhoek.",
    days: [
      {
        day: 1,
        heading: "Johannesburg",
        img: photoDay1,
        text: "Upon arrival at OR Tambo Airport in Johannesburg, we will depart for a short lunch stop before arriving at our lodge in the Dinokeng park. Depending on flight arrival time, we will then embark on a 3-hour sunset jeep safari, providing a truly immersive first day experience in Africa.",
      },
      {
        day: 2,
        img: photoDay2,
        heading: "Kruger National Park",
        text: "We will begin our day with an early departure for Kruger National Park, where we will take in the breathtaking views of the Panorama Route, including the Three Rondawels, Bourkes Luck Potholes, and Gods Window. Late afternoon, we will enjoy a sunset jeep safari through Manyeleti nature reserve, Timbavati.",
      },
      {
        day: 3,
        img: photoDay3,
        
        heading: "Kruger National Park",
        text: "Experience the beauty and wildlife of Kruger National Park on a full day jeep safari. During the day, we will have the opportunity to see the Big Five - lion, elephant, buffalo, rhinoceros, and leopard - and enjoy a traditional bush braai (BBQ) in the park.",
      },
      {
        day: 4,
        img: photoDay4,
        heading: "iSwatini (Swaziland)",
        text: "iSwatini (Swaziland), where we will begin the day with an early morning jeep safari, complete with a breakfast picnic. After lunch at Skukuza camp, we will travel by coach from Skukuza to iSwatini National Park, where we will have the opportunity to explore the natural beauty and diverse wildlife of the area.",
      },
      {
        day: 5,
        img: photoDay5,
        heading: "Zululand",
        text: "After visiting a traditional Swazi candle making experience, we cross the border back into South Africa.  Zululand, the Kingdom of the Zulu's.  This afternoon, we will embark on a jeep safari through the region",
      },
      {
        day: 6,
        img: photoDay6,
        heading: "St Lucia",
        text: "We will start the day with a morning jeep safari through Hluhluwe Park, followed by lunch in St Lucia. In the afternoon, we will take an estuary boat ride through the Isimangaliso Wetland Park, with the opportunity to see crocodiles and hippos. We will then embark on a 4x4-Unimog safari into the wetlands and dunes, including a seasonal tortoise safari. Back in St Lucia after dinner, your guide will take you on a walking search for hipposin town.",
      },
      {
        day: 7,
        img: photoDay7,
        heading: "Durban",
        text: "We continue our journey to Durban for our short inland flight to Port Elizabeth. Upon arrival, we will have a short orientation tour of Port Elizabeth.",
      },
      {
        day: 8,
        img: photoDay8,
        heading: "Knysna",
        text: "The start of the southern region towards the Garden Route, takes us to the beautiful town of Knysna. Our first stop will be Tsetsikamma National Park, where we will enjoy a hike throught the forest and also over a suspension bridge. Next, we will travel to Plettenberg Bay for a seasonal whale, seal, or dolphin boat safari. In the late afternoon, we will set sail on a catamaran cruise through the Knysna estuary, complete with South African sparkling wine and local oysters. A light finger meal will be served on the catamaran.",
      },
      {
        day: 9,
        img: photoDay9,
        heading: "Oudtshoorn",
        text: "We will set out on a journey towards Oudtshoorn, the ostrich capital of the world. We will begin the day with an experience through the Swartberg Pass, including a traditional lunch at Kobus se Gat. For those interested, an additional excursion, mountain biking through the Swartberg Pass. We will then visit the Cango Ostrich Show Farm and, weather permitting, enjoy star gazing or a safari as an additional excursion.",
      },
      {
        day: 10,
        img: photoDay10,
        heading: "Cape Town",
        text: "If weather permits, we will take a cable car ride up to the top of Table Mountain for breathtaking views of Cape Town. Our first wine tasting experience starts on a visit to the oldest wine estate in the heart of Cape Town. Groot Constantia, dating back to 1685.",
      },
      {
        day: 11,
        img: photoDay11,
        heading: "Cape Peninsula",
        text: "The day takes you on a meander route for a full day tour of the Cape Peninsula. Starting with a visit to Boulders Beach to see the penguin colony. From there, Cape Of Good Hope, where we will continue on to explore the breathtaking natural beauty and diverse Fauna and Flora of the region, with opportunities to stop at scenic viewpoints and take in the stunning views of the Atlantic Ocean and False Bay. ",
      },
      {
        day: 12,
        img: photoDay12,
        heading: "Cape Town",
        text:"Spend the day at leisure, taking the opportunity to do some last-minute shopping in Cape Town. Whether you prefer browsing local markets and boutiques, or visiting major department stores and shopping centers, Cape Town has something for every shopper. Take some time to relax and enjoy the city, or plan an additional activity or excursion to make the most of your day.  Alternatively, another lovely wine tasting day will complete your taste buds and fill your palet of the best wines of the Cape."}
      ,{
        day: 13,
        img: photoDay13,
        heading: "Optional Wine Tasting Day",
        text: "Join us for an optional wine tasting day to Stellenbosch and Franschhoek, the picturesque villages known for its French-influenced wines. We will take the wine tram, a hop-on hop-off service that travels between various wine estates in the region, allowing us to visit multiple estates and sample their wines. This is a great opportunity to discover the diversity of Franschhoek's wine offerings and learn about the winemaking process from the experts",
      },
      {
        day: 14,
        img:  photoDay14,
        heading: "Departure Day",
        text: "Today is your final departure day, with the flight time dependent on your specific itinerary. Please be sure to check the details of your travel arrangements and allow enough time to get to the airport. We hope you have enjoyed your time with us and take with you many memorable experiences"
      }
    ],
  
  },

];