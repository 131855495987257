import { useParams } from "react-router-dom";
import Itenary from "./components/itenary";
import { itenaries } from "./itenaryList"

export function ItenaryPage()
{
    let { id } = useParams<"id">();
    const click = () => 
    {
        console.log("Test : " + id);
    }
    return <div className="w-screen h-screen" onClick={click}>{
        
        itenaries.filter(x=> x.id === id).map(x=> <Itenary heading={x.heading} headingImg={x.headingImg} overview={x.overview} days={x.days} ></Itenary>)}</div>;
}