import React from "react";
type ItenaryProps = {
  heading: string[];
  headingImg: string;
  overview: string;
  days: {
    day: number;
    img: string;
    heading: string;
    text: string;
  }[];
};

const Itenary: React.FC<ItenaryProps> = ({
  heading,
  headingImg,
  days,
  overview,
}) => {
  return (
    <>
      <div className="relative font-black lg:h-96 sm:h-80">
        <div className="w-full h-full overflow-hidden">
          <img
            src={headingImg}
            className="object-cover w-full"
            alt="wine and dine"
          />
        </div>
        <div>
          <h1 className="absolute top-0 left-0 p-8 mb-2 text-2xl leading-10 cursor-default lg:p-16 lg:text-4xl lg:leading-relaxed font-playfair-display">
            {heading.map((h) => (
              <>
                <span className="pl-2 pr-2 bg-white bg-opacity-70">{h}</span>
                <br />
              </>
            ))}
          </h1>
        </div>

        <div className="w-full h-2 bg-black"></div>

        <div className="bg-gray-50 lg:grid lg:grid-cols-5">
          <div className="flex flex-row bg-white shadow-lg lg:col-start-2 lg:col-end-5">
            <div>
              <ul className="w-full space-y-2 divide-y">
                <li>
                  <h1 className="w-full text-2xl font-black text-center bg-white pt-9 pb-9 font-playfair-display">
                    Overview
                  </h1>
                  <p className="pb-2 m-6 mb-8 text-lg font-medium leading-8 tracking-wider text-center text-gray-600 cursor-default font-lato">
                    {overview}
                  </p>
                </li>
                {days.map((day, index) =>
                  index % 2 === 0 ? (
                    <li>
                      <h1 className="w-full text-2xl font-black text-center bg-white pt-9 pb-9 font-playfair-display">
                        Day {day.day} - {day.heading}
                      </h1>
                      <div className="flex flex-col items-center w-full lg:flex-row">
                        <div className="overflow-hidden bg-gray-200 rounded-lg h-60 w-60 lg:m-6">
                          <img
                            src={day.img}
                            alt={"day: " + day.day}
                            className="object-cover min-w-full min-h-full"
                          />
                        </div>
                        <div className="m-6 text-base font-medium leading-8 tracking-wider text-center text-gray-600 cursor-default lg:w-2/3 font-lato">
                          {day.text}
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li>
                      <h1 className="w-full text-2xl font-black text-center bg-white pt-9 pb-9 font-playfair-display">
                        Day {day.day} - {day.heading}
                      </h1>
                      <div className="flex flex-col items-center justify-between w-full lg:flex-row">
                        <div className="hidden m-6 text-base font-medium leading-8 tracking-wider text-center text-gray-600 lg:block font-lato lg:w-2/3">
                          {day.text}
                        </div>
                        <div className="overflow-hidden bg-gray-200 rounded-lg h-60 w-60 lg:m-6">
                          <img
                            src={day.img}
                            alt={"day: " + day.day}
                            className="object-cover min-w-full min-h-full"
                          />
                        </div>
                        <div className="block m-6 text-base font-medium leading-8 tracking-wider text-center text-gray-600 lg:hidden font-lato lg:w-2/3">
                          {day.text}
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full pt-12 text-white bg-black font-playfair-display">

          <div className="flex flex-col mt-12 space-y-4 text-2xl text-center">
          <div className="pt-8 pb-12 text-center">For more information</div>
            <a
              href="mailto:rsaguide@gmail.com"
              className="font-medium text-gray-200 text-md hover:underline focus:outline-none focus:underline font-lato"
            >
             E-mail : rsaguide@gmail.com
            </a>
            <a
              href="tel:+27787304515"
              className="pb-24 font-medium text-gray-200 text-md hover:underline focus:outline-none focus:underline font-lato"
            >
              Phone : (+27) 78 730 4515
            </a>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Itenary;
