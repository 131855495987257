import "./App.css";
import coverPicture from "./assets/cover-picture.jpg";
import elephants from "./assets/elephants.jpg";


import Itenary from "./components/itenary";
import { itenaries } from "./itenaryList";

function App() {
  return (
    <div>
      <section className="relative flex flex-col items-center justify-center w-full h-screen">
        <div className="absolute bottom-0 right-0 w-full h-full ">
          <img
            src={coverPicture}
            className="object-cover w-full h-screen "
            alt="cover"
          />
        </div>
        <div className="fixed top-0 z-10 p-4 text-gray-500 w-80 right-6">
          
        </div>
        <h1 className="relative inset-y-0 p-10 text-6xl antialiased text-center cursor-default font-sorts-mill-goudy bg-yellow lg:text-9xl">
        Your Journey starts Here
        </h1>
      </section>
      <section className="relative flex flex-col items-center justify-center w-full h-screen p-2 text-5xl text-center text-white bg-black font-sorts-mill-goudy lg:text-9xl">
      Discover the beauty <br/>and diversity of Africa
      </section>
      <div className="relative w-full h-screen text-center font-sorts-mill-goudy">
      <section className="relative flex flex-col items-center justify-center w-full h-screen text-white bg-black">
      <img
            src={elephants}
            className="object-cover w-full h-screen "
            alt="cover"
          />
      </section>
      <section className="relative flex flex-col w-full text-white bg-black h-min-screen">
        <h1 className="w-full pt-5 mt-12 mb-12 text-4xl font-sorts-mill-goudy">Holiday Packages</h1>
        <p className="text-lg tracking-wider text-gray-200 font-lato">Escape the ordinary and embark on an adventure with our tour packages. From safari expeditions to cultural immersions, we have something for everyone.</p>
        <div className="flex flex-col justify-center mt-12 lg:flex-row">
          {itenaries.map(x=> {
            return (
            
              <a href={"/itenary/"+ x.id}  className="relative m-12 overflow-hidden text-4xl font-black rounded-lg cursor-wait w-60 h-60 lg:w-96 lg:h-96 ">
              <div className="w-full h-full overflow-hidden">
                <img
                  src={x.headingImg}
                  className="object-cover w-full h-full"
                  alt="wine and dine"
                />
              </div>
              <div className="flex flex-col m-4 ">
                <h1 className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full mb-2 cursor-pointer lg:p-16 lg:leading-relaxed font-playfair-display hover:bg-black hover:bg-opacity-60">
                  {x.heading.map((h) => (
                    <a href={"/itenary/"+ x.id} >
                      <span className="top-0 w-full text-2xl leading-none text-black bg-white lg:leading-loose lg:p-2 lg:text-4xl bg-opacity-70">{h}</span>
                      <br />
                    </a>
                  ))}
                </h1>
              </div>
              </a>
            
           )}   
          )}
        </div>
        </section>
        <div className="w-full pt-12 text-black bg-white font-playfair-display">

          <div className="flex flex-col mt-12 space-y-4 text-2xl text-center">
          <div className="pt-8 pb-12 font-bold text-center">For more information</div>
            <a
              href="mailto:rsaguide@gmail.com"
              className="font-medium text-gray-800 text-md hover:underline focus:outline-none focus:underline font-lato"
            >
             E-mail : rsaguide@gmail.com
            </a>
            <a
              href="tel:+27787304515"
              className="pb-24 font-medium text-gray-800 text-md hover:underline focus:outline-none focus:underline font-lato"
            >
              Phone : (+27) 78 730 4515
            </a>
          </div>
        </div>
      </div>
      
     {/* <Itenary days={ItenaryDay.days} heading={ItenaryDay.heading} headingImg={ItenaryDay.headingImg} overview={ItenaryDay.overview}></Itenary> */}
    </div>
  );
}

export default App;
